//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { STable, XCard } from '@/components'
import { sysDictTypePage, sysDictTypeDelete, sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import addForm from './addForm'
import editForm from './editForm'
import dataIndex from './dictdata/index'
export default {
  components: {
    XCard,
    STable,
    addForm,
    editForm,
    dataIndex
  },
  data () {
    return {
      // 查詢參數
      queryParam: {},
      // 表頭
      columns: [
        {
          title: '類型名稱',
          dataIndex: 'name'
        },
        {
          title: '唯一編碼',
          dataIndex: 'code'
        },
        {
          title: '排序',
          dataIndex: 'sort'
        },
        {
          title: '備註',
          dataIndex: 'remark',
          width: 200
        },
        {
          title: '狀態',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' }
        }, {
          title: '操作',
          width: '150px',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加載數據方法 必須為 Promise 對象
      loadData: parameter => {
        return sysDictTypePage(Object.assign(parameter, this.queryParam)).then((res) => {
          return res.data
        })
      },
      statusDict: []
    }
  },
  created () {
    this.sysDictTypeDropDown()
  },
  methods: {
    statusFilter (status) {
      // eslint-disable-next-line eqeqeq
      const values = this.statusDict.filter(item => item.code == status)
      if (values.length > 0) {
        return values[0].value
      }
    },
    /**
     * 獲取字典數據
     */
    sysDictTypeDropDown () {
      sysDictTypeDropDown({ code: 'common_status' }).then((res) => {
        this.statusDict = res.data
      })
    },
    sysDictTypeDelete (record) {
      sysDictTypeDelete(record).then((res) => {
        if (res.success) {
          this.$message.success('刪除成功')
          this.$refs.table.refresh()
        } else {
          this.$message.error('刪除失敗：' + res.message)
        }
      }).catch((err) => {
        this.$message.error('刪除錯誤：' + err.message)
      })
    },
    handleOk () {
      this.$refs.table.refresh()
    }
  }
}
